import { createContext, useContext, useState, useEffect } from 'react';
import { DeleteSessionAsync, LoadSessionDataAsync } from './Session';

const SessionContext = createContext();
const initialSessionData = {
  logged_in: false,
  steam_id: '',
  avatar: '',
  name: '',
  la: -1,
  cr: -1
}

export const SessionProvider = ({ children }) => {
    const [sessionData, setSessionData] = useState(initialSessionData);
    const loadSessionDataAsync = async () => { const result = await LoadSessionDataAsync(); setSessionData(result); return result?.logged_in ?? false; }
    const deleteSessionAsync = async () => { const result = await DeleteSessionAsync(); setSessionData(result); return result?.logged_in ?? false; }
    useEffect(() => {
      const load = async () => {
        await loadSessionDataAsync();
      }
      load();
      const renewSession = async () => {
        if(DEBUG) console.log("Session renewed");
        await loadSessionDataAsync();
      }
      
      // Setze ein Intervall von z.B. 5 Minuten (300000 ms)
      const interval = setInterval(renewSession, 300000);
      return () => clearInterval(interval);
    }, []);
    return (
        <SessionContext.Provider value={{sessionData, loadSessionDataAsync, deleteSessionAsync, setSessionData }}>
            {children}
        </SessionContext.Provider>
    );
};

export const useSession = () => useContext(SessionContext);