import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Checkout } from "../PayPalCheckout/Checkout";
import { useTranslation } from "react-i18next";
import { Modal, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useCart } from "./CartContext";
import { useEffect, useState } from "react";
import classes from "./CartModal.module.css";
import { useLocation } from "react-router-dom";
import React from "react";
import { DEBUG } from "../..";

const initialOptions = {
    //"clientId": "ASDzGW8u7SIlQLlxhzw8Ccym2OnE4tNlDqsP8mFOFjfBoYhnEF-QhzRGVsO55G3GB8KlDZLKVXPa8YYV", // Sandbox
    "clientId": "AXceADuqlLXB6peN9TeEUY-dIc0Zf5cPWi1hVwoEUmdb5EC3pMtWjhk05X7FgVx9RLxVwpvqSXtY15li", //Production
    currency: "EUR",
    intent: "capture",
    disableFunding: "credit",
};
interface CartProps {
    logged_in: boolean;
  }
  function checkForCart(iterator: IterableIterator<string>): boolean {
    const arrayFromIterator = Array.from(iterator);
    return arrayFromIterator.includes('cart');
  }
  function checkForSteamCart(iterator: IterableIterator<string>): boolean {
    const arrayFromIterator = Array.from(iterator);
    return arrayFromIterator.includes('scart');
  }
  
export function CartModal({ logged_in }: CartProps){
    const location = useLocation();
    const [ steamCartRedirect, setSteamCart ] = useState<boolean>();
    const { t } = useTranslation("translation");
    const isMobile = useMediaQuery("(max-width: 50em)");
    const { opened, openCart, closeCart } = useCart();
    useEffect(() => {
        if(DEBUG) console.log("opened cart ", opened);
    }, [opened]);
    useEffect(() => {
        if(DEBUG) console.log("logged_in cart ", logged_in);
    }, [logged_in]);

    // Check for modal query param and handle modal state
    React.useEffect(() => {
        if(DEBUG) console.log("Location ", location);
        const params = new URLSearchParams(location.search);
        if(DEBUG) console.log("Params ", params);
        const modalParam = checkForCart(params.keys());
        const steamModalParam = checkForSteamCart(params.keys());
        if(DEBUG) console.log("Params2 ", modalParam);

        if (modalParam) {
            openCart();
        } else if (steamModalParam) {
            setSteamCart(true);
        } else {
            closeCart();
        }
    }, [location.search]);

    useEffect(() => {
        if(steamCartRedirect && logged_in)
            openCart();
    }, [logged_in]);
    return (
        <Modal
            className={classes.cartModal}
            size="lg"
            opened={opened && logged_in}
            onClose={closeCart}
            title={<Title>{t("cta.main.cart")}</Title>}
            fullScreen={isMobile}>
        
            <PayPalScriptProvider options={initialOptions}>
                <Checkout logged_in={logged_in} />
            </PayPalScriptProvider>
        </Modal>
    );
}