import React from 'react';
import classes from './Checkout.module.css';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { Text, NumberInput, Loader, Divider } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../Cart/CartContext';
import { useTranslation } from "react-i18next";
import { DEBUG } from '../..';

interface CheckoutProviderProps {
  logged_in: boolean; // Deklaration der Prop logged_in
  isPending: boolean; // Deklaration der Prop logged_in
  onApproveNavigation: () => void;
  t: (key: string) => string;
}

export type Product = {
  name: string;
  value: number;
  tax: number;
  discount: Discount;
}

export type Discount = {
  discount: number;
  start: Date;
  end: Date;
  affiliate: string;
}

const HolidayDiscount: Discount = {
  discount: 5.00,
  start: new Date(2024, 11, 19, 0, 0, 0),
  end: new Date(2024, 11, 31, 23, 59, 59),
  affiliate: ""
}

const ACCDRIVE: Product = {
  name: "ACC DRIVE",
  value: 25.00,
  tax: 3.99,
  discount: HolidayDiscount
}

const initialState = {
  quantity: 1,
  product: ACCDRIVE,
  orderID: "",
  onApproveMessage: "",
  onErrorMessage: "",
  logged_in: false,
  isPending: false
};
export class CheckoutProvider extends React.Component<CheckoutProviderProps, typeof initialState> {
  
  activeDiscount = 0.00;
  constructor(props: CheckoutProviderProps) {
    super(props);
    this.state = {
      ...initialState,
      logged_in: props.logged_in,
      isPending: props.isPending
    };

    this.onChange = this.onChange.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.onApprove = this.onApprove.bind(this);
    this.onError = this.onError.bind(this);
    this.onClick = this.onClick.bind(this);
    var now = new Date();
    this.activeDiscount = now >= this.state.product.discount.start && now <= this.state.product.discount.end ? this.state.product.discount.discount : 0.00
    console.log(`now: ${now}`);
    console.log(`discount: ${this.state.product.discount}`);
    console.log(`start: ${this.state.product.discount.start}`);
    console.log(`end: ${this.state.product.discount.end}`);
    console.log(`after start: ${(now >= this.state.product.discount.start)}`);
    console.log(`after start: ${(now <= this.state.product.discount.end)}`);
  }
  componentDidUpdate(prevProps: CheckoutProviderProps) {
    if (prevProps.isPending !== this.props.isPending) {
      this.setState({ isPending: this.props.isPending });
    }
  }
  onChange(value: any) {
    if(value == "") value = 1;
    this.setState({
      quantity: value,
      orderID: "",
      onApproveMessage: "",
      onErrorMessage: ""
    });
  }

  createOrder(data: Record<string, unknown>, actions: any) {
    const ref = localStorage.getItem("ref") ?? "";
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: (this.state.quantity * (this.state.product.value - this.activeDiscount)).toFixed(2),
              breakdown: {
                item_total: {
                  value: (this.state.quantity * (this.state.product.value - this.activeDiscount)).toFixed(2),
                  currency_code: "EUR"
                }
              }
            },
            items: [
              {
                name: this.state.product.name,
                quantity: this.state.quantity,
                unit_amount: {
                  value: (this.state.product.value - this.activeDiscount),
                  currency_code: "EUR"
                },
                image_url: "https://accdrive.com/static/media/drive-logo.1a151a4c09722e32d368.png",
                url: "https://accdrive.com/"
              }
            ]
          }
        ]
      })
      .then((orderID: any) => {
        this.setState({ orderID: orderID });
        this.logOrder(ref, orderID)
        return orderID;
      });
  }

  async logOrder(ref: string | null, id: string) {
    const formData = new FormData();
    formData.append('amount', this.state.quantity.toString());
    formData.append('affiliate', ref ?? "");
    formData.append('id', id)
    formData.append('st', 'CREATED');

    try {
      const response = await fetch('/dyn/process-order.php?mode=create', {
        method: 'POST',
        body: formData,
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const result = await response.json();
    } catch (error: any) {
      if(DEBUG) console.error("Order Error", error.toString());
    }
  };

  async handleSubmit(ref: string | null, givenName: string, surName: string, email: string, id: string, status: string) {
    const formData = new FormData();
    formData.append('amount', this.state.quantity.toString());
    formData.append('affiliate', ref ?? "");
    /*formData.append('gn', givenName);
    formData.append('sn', surName);
    formData.append('em', email);*/
    formData.append('id', id);
    formData.append('st', status);

    try {
      const response = await fetch('/dyn/process-order.php?mode=confirm', {
        method: 'POST',
        body: formData,
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const result = await response.json();
    } catch (error: any) {
      if(DEBUG) console.error("Order Error", error.toString());
    }
  };

  async handleSubmitRetry(ref: string | null, givenName: string, surName: string, email: string, id: string, status: string) {
    const formData = new FormData();
    formData.append('amount', this.state.quantity.toString());
    formData.append('affiliate', ref ?? "");
    formData.append('gn', givenName);
    formData.append('sn', surName);
    formData.append('em', email);
    formData.append('id', id);
    formData.append('st', status);
  
    let attempt = 0;
    const maxAttempts = 10;
  
    while (attempt < maxAttempts) {
      try {
        const response = await fetch('/dyn/process-order.php', {
          method: 'POST',
          body: formData,
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const result = await response.json();
        
        if (result[0]?.State === 'ERROR') {
          attempt++;
          if (attempt >= maxAttempts) {
            throw new Error(result[0]?.Error || 'Max attempts reached with errors.');
          }
        } else {
          // Erfolgreiche Antwort behandeln
          console.log('Order successfully processed:', result);
          break;
        }
      } catch (error: any) {
        if (DEBUG) console.error("Order Error", error.toString());
        attempt++;
        if (attempt >= maxAttempts) {
          console.error('Max attempts reached. Final error:', error.toString());
          break;
        }
      }
    }
  }
  
  onApprove(data: any, actions: any) {
    let app = this;
    if(DEBUG) console.log("Capture", actions.order.capture());
    if(DEBUG) console.log("Capture Payer", actions.order);
    if(DEBUG) console.log("Get", actions.order.get());
    return actions.order.capture().then(async function (details: any) {
      const ref = localStorage.getItem("ref") ?? "";
      if(DEBUG) console.log(ref);
      await app.handleSubmit(ref, details.payer.name.given_name, details.payer.name.surname, details.payer.email_address, details.id, details.status);
      app.setState({
        onApproveMessage: `Transaction completed by ${details.payer.name.given_name}!`
      });
      
      app.props.onApproveNavigation();
    });
  }

  onError(err: Record<string, unknown>) {
    this.setState({
      onErrorMessage: err.toString()
    });
  }

  onClick() {
    if(DEBUG) console.log("When clicked, amount was", this.state.quantity);
  }

  render() {
    const { t } = this.props;
    return (
      this.state.logged_in ? (
        <div className={classes.styledPaypal}>
          {this.state.isPending ? <Loader color="var(--mantine-color-green-filled)" size="xl" /> : (
            <>
              <div className={classes.amountSelection}>
                <Text className={classes.amountLabel}>
                  {this.state.product.name} {t('cart.amount')} {/* Localized text */}
                </Text>
                <NumberInput
                  className={classes.styledInput}
                  styles={{
                    root: { width: '100%', maxWidth: '100px', justifySelf: 'end' },
                    input: { width: '100%'}
                  }}
                  min={1}
                  max={50}
                  stepHoldDelay={500}
                  stepHoldInterval={100}
                  allowDecimal={false}
                  allowNegative={false}
                  value={this.state.quantity}
                  onChange={this.onChange}
                  clampBehavior="strict"
                />
              </div>
              <Divider my={"sm"} variant={"dashed"}/>
              <div className={classes.cartSumSelection}>
                <Text className={classes.amountLabel}>
                  {t('cart.sum')} {/* Localized text */}
                </Text>
                {(this.activeDiscount > 0.00 ? 
                  <div className={classes.cartSumDiscountGroup}>
                    <Text className={`${classes.cartSumDiscounted} ${classes.strikethrough}`}>
                      {(this.state.product.value * this.state.quantity).toFixed(2)}€ {/* Localized text */}
                    </Text>
                    <Text className={classes.cartSum}>
                      {((this.state.product.value - this.activeDiscount) * this.state.quantity).toFixed(2)}€ {/* Localized text */}
                    </Text>
                  </div> : 
                  <>
                    <Text className={classes.cartSum}>
                      {(this.state.product.value * this.state.quantity).toFixed(2)}€ {/* Localized text */}
                    </Text>
                  </>)}
              </div>
              <Divider my={"sm"} />
              <PayPalButtons
                style={{ layout: "vertical", color: "gold", shape: "rect" }}
                createOrder={this.createOrder}
                onApprove={this.onApprove}
              />
            </>
          )}
        </div>
      ) : (
        <></>
      )
    );
  }
}
export const Checkout = (logged_in: any) => {
  const { closeCart } = useCart();
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const redirectToProfile = () => {
    closeCart();
    navigate("/account");
  };
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  return (
    <CheckoutProvider logged_in={logged_in} isPending={isPending} onApproveNavigation={redirectToProfile} t={t} />
  )
}