import { createContext, useContext, useState, useEffect } from 'react';
import { useSession } from "../../auth/SessionContext";
import { DEBUG } from '../..';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
    //const [opened ,{ open: openCart, close: closeCart }] = useDisclosure(false);
    const { sessionData } = useSession();
    const [opened, setOpened] = useState(false);

    const handleSteamLogin = async () => {
        try {
          // Redirect the user to the Steam authentication page on your server
          window.location.href = "dyn/auth/init-openId.php?cart";
        } catch (error) {
          if(DEBUG) console.error("Error initiating Steam login:", error);
        }
      };
    const openCart = () => { 
      if(DEBUG) console.log("openCart logged_in", sessionData.logged_in); 
      
      return sessionData.logged_in ? setOpened(true) :  handleSteamLogin(); 
    }
    const closeCart = () => setOpened(false);
    useEffect(() => {
      if(DEBUG) console.log("opened cart ", opened);
    }, [opened]);

    return (
        <CartContext.Provider value={{ opened, openCart, closeCart }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);